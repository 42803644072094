














import Vue from "vue";
import http from "../utils/axios";
import PostAdForm from "@/components/BikesAdForm.vue";
import Header from "@/views/Header.vue";
export default Vue.extend({
  name: "location-chooser",
  components: {
    "post-ad-form": PostAdForm,
    "header-top": Header
  }
});
